/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/axios@1.1.3/dist/axios.min.js
 * - /npm/i18n-js@4.1.1/dist/browser/index.js
 * - /npm/jquery-validation@1.19.5/dist/jquery.validate.min.js
 * - /npm/popper.js@1.16.1/dist/umd/popper.min.js
 * - /npm/js-cookie@3.0.1/dist/js.cookie.min.js
 * - /npm/i18n-js@3.9.2/app/assets/javascripts/i18n.min.js
 * - /npm/rater-jquery@1.0.0/rater.min.js
 * - /npm/vue-the-mask@0.11.1/dist/vue-the-mask.min.js
 * - /npm/vue-observe-visibility@1.0.0/dist/vue-observe-visibility.umd.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
